<template>
  <div class="box">
    <!-- 问题类型 -->
    <div class="item_box menu">
      <p class="force">
        <SubTitle :title="'问题类型：'" class="flex_item" />
        <span class="feedback">{{ classificationFunc(classification) }}</span>
      </p>
    </div>
    <!-- 选择商品 -->
    <div v-if="classification === 1" class="item_box flex flex_align_center pr" style="padding: 0">
      <DetailListCell
        v-if="Object.keys(selectPro).length !== 0"
        :data="selectPro"
        :isEdit="isEdit"
      />
      <div class="pa state" :class="{ online: state == '已受理' }">
        {{ state }}
      </div>
    </div>
    <!-- 问题描述 -->
    <div class="item_box">
      <div class="flex flex_align_center" @click="menuIndex">
        <SubTitle :title="'问题描述：'" />
        <span class="flex_item issue">{{ menuText }}</span>
      </div>
      <textarea
        placeholder="请输入您要填写的问题描述"
        name=""
        id=""
        cols="30"
        rows="10"
        maxlength="100"
        class="textarea"
        v-model="content"
        :disabled="!isEdit"
      ></textarea>
    </div>
    <div class="item_box" v-if="!isEdit && replyContent">
      <div class="service">
        <span class="icon_index"></span>
        <span class="email">客服回复：</span>
      </div>
      <div class="message">{{ replyContent }}</div>
    </div>
    <!-- 上传图片 -->
    <div class="item_box" v-if="classification === 1">
      <div>
        <SubTitle :title="'批次号图片'" style="margin-bottom: 0.1rem" />
        <UploadImg
            :proImgList="piImgList"
            :isEdit="isEdit"
            @addImg="
          (data) => {
            this.piImgList.push(data);
          }
        "
            @removeImg="
          (data) => {
            this.piImgList.forEach((item, index) => {
              if (item.url == data) {
                this.piImgList.splice(index, 1);
                return false;
              }
            });
          }
        "
        />
        <SubTitle :title="'产品图片'" style="margin: 0.1rem 0 0.1rem" />
        <UploadImg
            :proImgList="proImgList"
            :isEdit="isEdit"
            @addImg="
          (data) => {
            this.proImgList.push(data);
          }
        "
            @removeImg="
          (data) => {
            this.proImgList.forEach((item, index) => {
              if (item.url == data) {
                this.proImgList.splice(index, 1);
                return false;
              }
            });
          }
        "
        />
      </div>
    </div>
    <!--  图片补充  -->
    <div class="item_box" v-if="classification !== 1 && replenishImgList && replenishImgList.length > 0">
      <div>
        <SubTitle :title="'图片补充'" style="margin: 0.1rem 0 0.1rem" />
        <UploadImg
            :proImgList="replenishImgList"
            :isEdit="isEdit"
            @addImg="
          (data) => {
            this.replenishImgList.push(data);
          }
        "
            @removeImg="
          (data) => {
            this.replenishImgList.forEach((item, index) => {
              if (item.url == data) {
                this.replenishImgList.splice(index, 1);
                return false;
              }
            });
          }
        "
        />
      </div>
    </div>
    <!-- 问题大类 -->
    <van-popup
      v-model="isShowClass"
      :close-on-click-overlay="true"
      round
      position="bottom"
      :style="{ height: '50%' }"
    >
      <div
        class="flex flex_align_center list"
        v-for="(item, index) in classList"
        :key="index"
        @click="selectClass(item.text, index)"
      >
        <div class="flex_item role">{{ index + 1 }}、{{ item.text }}</div>
        <div class="icon_radio" :class="{ ac: item.isSelect }"></div>
      </div>
    </van-popup>
    <!-- 问题二级类目 -->
    <van-popup
      v-model="isShowTwoClass"
      :close-on-click-overlay="true"
      round
      position="bottom"
      :style="{ height: '30%' }"
    >
      <div
        class="flex flex_align_center list"
        v-for="(item, index) in menuList"
        :key="index"
        @click="selectTwoClass(item.questionsMenu, index, item.menuId)"
      >
        <div class="flex_item role">
          {{ index + 1 }}、{{ item.questionsMenu }}
        </div>
        <div class="icon_radio" :class="{ ac: item.isSelect }"></div>
      </div>
    </van-popup>
    <!-- 弹框 -->
    <van-popup
      v-model="isShowProDialog"
      round
      position="bottom"
      :style="{ height: '63%' }"
      class="pop_box"
    >
      <SelectList
        @onSelectPro="onSelectPro"
        :proList="proList"
        @addProList="
          (data) => {
            this.proList = data;
          }
        "
      />
    </van-popup>
    <div v-if="isEdit">
      <button class="btn_submit" @click="submitFeedback">提交</button>
      <router-link to="/feedback">
        <button class="btn_to_list">反馈历史</button>
      </router-link>
    </div>
  </div>
</template>
<script>
import {
  getFeedbackDetail,
  getProList,
  userQuestionsSave,
  getReplayContent,
  getSecondClass,
} from "@/api/feedback/base";
import SubTitle from "./components/SubTitle.vue";
import UploadImg from "./components/UploadImg.vue";
import SelectList from "./components/SelectList.vue";
import DetailListCell from "./components/DetailListCell.vue";
import defaultImg from "@/assets/feedback/default.png";
import { Toast } from "vant";
export default {
  components: {
    SubTitle,
    UploadImg,
    SelectList,
    DetailListCell,
  },
  name: "FeedbackDetail",
  data() {
    return {
      images: {
        iconArrow: require("@/assets/feedback/fan.png"),
      },
      isShowProDialog: false, //遮罩层
      isEdit: true,
      selectTag: "商品反馈",
      content: "",
      showList: "",
      replenishList: [],
      replenishImgList: [],
      piImgList: [],
      proImgList: [],
      proList: [],
      selectPro: {},
      state: "",
      classification: null,
      isShowClass: false,
      selectLoading: false,
      selectFinished: false,
      classList: [
        // {
        //   text: "优化意见（建议）",
        //   isSelect: true,
        // },
        // {
        //   text: "其他",
        // },
        {
          text: "商品反馈",
          isSelect: true,
        },
        // {
        //   text: "小程序bug",
        // },
        // {
        //   text: "活动反馈",
        // },
        // {
        //   text: "物流反馈",
        // },
      ],
      replyContent: "",
      menuId: "10000",
      menuText: "",
      menuList: [],
      isShowTwoClass: false,
    };
  },
  created() {
    let { id } = this.$route.query;
    //有id的话才是编辑状态
    if (id) {
      this.isEdit = false;
      this.loadFeedbackDetail(id);
      this.loadReplayContent(id);
    }
  },
  methods: {
    classificationFunc(str){
      return str = str === 1 ? '商品反馈':str === 2 ? '服务反馈':str === 3 ? '功能反馈':str === 4 ? '我要吐槽': ''
    },
    //打开订单产品弹层
    openSelectProDialog() {
      if (this.proList.length > 0) {
        this.isShowProDialog = true;
      } else {
        Toast("您暂时无商城订单!");
      }
    },
    loadProList() {
      getProList({
        pageNum: 1,
        pageSize: 5,
      }).then((res) => {
        Toast.clear();
        let { rows } = res;
        this.proList = rows;
      });
    },
    onSelectPro(index, ins) {
      this.selectPro = {};
      for (let i = 0; i < this.proList.length; i++) {
        this.proList[i].children.forEach((item) => {
          this.$set(item, "isSelect", false);
        });
      }
      this.$set(this.proList[index].children[ins], "isSelect", true);
      this.isShowProDialog = false;

      const { parentOrderNo, children } = this.proList[index];
      const { image, goodsTitle, skuAttr, ctime, buyNum, goodsId, orderId } =
        children[ins];
      this.selectPro = {
        parentOrderNo,
        image: image ? image : defaultImg,
        goodsTitle,
        skuAttr,
        ctime,
        buyNum,
        goodsId,
        orderId,
      };
      Toast.loading({
        message: "加载中",
        forbidClick: true,
        duration: 0,
      });
      getSecondClass({ goodsId }).then((res) => {
        if (res.data == "") {
          Toast.fail("没有获取到二级类目!");
        }
        Toast.clear();
        this.menuList = res.data;
      });
    },
    loadFeedbackDetail(id) {
      getFeedbackDetail(id).then((res) => {
        let { data } = res;
        const {
          parentOrderNo,
          image,
          goodsTitle,
          skuAttr,
          ctime,
          buyNum,
          content,
          piciImg,
          addImg,
          proImg,
          state,
          questionsMenu,
          classification,
        } = data;

        this.selectPro = {
          parentOrderNo,
          image,
          goodsTitle,
          skuAttr,
          ctime,
          buyNum,
        };
        this.menuText = questionsMenu;
        this.content = content;
        this.piImgList = this.getPiImgListFire(piciImg);
        this.proImgList = this.getPiImgListFire(proImg);
        this.replenishImgList = this.getPiImgListFire(addImg);
        this.state = state;
        this.classification = classification;
      });
    },
    submitFeedback() {
      if (Object.keys(this.selectPro).length === 0) {
        Toast("请先选择产品哦~");
        return false;
      }
      if (!this.content) {
        Toast("请输入您要填写的问题描述~");
        return false;
      }
      if (this.piImgList.length == 0) {
        Toast("请添加批次号图片");
        return false;
      }
      if (this.proImgList.length == 0) {
        Toast("请添加产品图片");
        return false;
      }
      const {
        buyNum,
        goodsTitle,
        image,
        parentOrderNo,
        ctime,
        skuAttr,
        goodsId,
        orderId,
      } = this.selectPro;
      userQuestionsSave({
        parentOrderNo,
        buyNum,
        goodsTitle,
        image,
        ctime,
        skuAttr,
        goodsId,
        orderId,
        typeNo: 3, // 暂时写死 3 商品反馈
        content: this.content,
        piciImg: JSON.stringify(this.getPiImgList()),
        proImg: JSON.stringify(this.getProimgList()),
        userId: sessionStorage.getItem("userId"),
        menuId: this.menuId,
      }).then(() => {
        Toast.success("提交成功");
        this.$router.push({
          path: "/feedback"
        });
      });
    },
    getPiImgList() {
      let arr = [];
      this.piImgList.forEach((item) => {
        arr.push(item.url);
      });
      return arr;
    },
    getProimgList() {
      let arr = [];
      this.proImgList.forEach((item) => {
        arr.push(item.url);
      });
      return arr;
    },
    getPiImgListFire(piciImg) {
      if (piciImg) {
        let arr = [];
        JSON.parse(piciImg).forEach((item) => {
          arr.push({
            url: item,
          });
        });
        return arr;
      }
    },
    listBox() {
      if (this.isEdit) this.isShowClass = true;
    },
    menuIndex() {
      if (this.isEdit) this.isShowTwoClass = true;
    },
    selectClass(text, index) {
      this.selectTag = text;
      this.classList.forEach((item) => {
        this.$set(item, "isSelect", false);
      });
      this.$set(this.classList[index], "isSelect", true);
      this.isShowClass = false;
    },
    loadReplayContent(id) {
      getReplayContent(id).then((res) => {
        if (res.data.length > 0) {
          this.replyContent = res.data[0].replyContent;
        }
      });
    },
    selectTwoClass(text, index, menuId) {
      this.menuText = text;
      this.menuId = menuId;
      this.menuList.forEach((item) => {
        this.$set(item, "isSelect", false);
      });
      this.$set(this.menuList[index], "isSelect", true);
      this.isShowTwoClass = false;
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  .issue {
    display: block;
    font-size: 0.14rem;
    font-weight: 700;
  }
  .list {
    height: 0.19rem;
    padding: 0.23rem 0.2rem;
    border-bottom: 0.01rem solid #e5e5e5;
    .role {
      font-size: 0.14rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      opacity: 1;
    }
  }
  .icon_radio {
    background: url("../../assets/feedback/radio.png") no-repeat;
    background-size: 100% 100%;
    width: 0.15rem;
    height: 0.15rem;
    margin-left: 0.4rem;
    &.ac {
      background: url("../../assets/feedback/radio-select.png");
      background-size: 100% 100%;
      width: 0.15rem;
      height: 0.15rem;
    }
  }
  .item_box {
    background: #fff;
    margin: 0.1rem 0.1rem 0 0.1rem;
    padding: 0.1rem;
    border-radius: 0.07rem;
    margin-bottom: 0.05rem;
    position: relative;
    .service {
      display: flex;
      height: 0.28rem;
      align-items: center;
      .icon_index {
        width: 0.135rem;
        height: 0.135rem;
        background: url("../../assets/feedback/tel.png") no-repeat;
        background-size: 100%;
      }
      .email {
        font-size: 0.13rem;
        font-weight: 500;
        color: #333;
        margin-left: 0.05rem;
      }
    }
    .message {
      width: 3.35rem;
      overflow-y: auto;
      height: 1rem;
      box-sizing: border-box;
      border-radius: 0.1rem;
      padding: 0.1rem;
      font-size: 0.12rem;
      line-height: 0.2rem;
      border: 1px solid #999999;
    }
  }
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .force {
      display: flex;
      align-items: center;
      .feedback {
        font-size: 0.14rem;
        font-family: PingFangSC-Regular;
        color: #000000;
        font-weight: 700;
        opacity: 1;
      }
    }
    .mask {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
  .choice {
    height: 0.21rem;
    padding: 0rem 0.25rem;
    font-size: 0.01rem;
    color: #999999;
  }
  .icon_arrow {
    width: 0.12rem;
    height: 0.06rem;
  }

  .textarea {
    width: 3.35rem;
    height: 1rem;
    box-sizing: border-box;
    border-radius: 0.1rem;
    padding: 0.1rem;
    font-size: 0.12rem;
    margin-top: 0.1rem;
    border: 1px solid #999999;
    background-color: #fff;
    line-height: 0.2rem;
    resize: none;
  }
  .limt-text {
    text-align: right;
    position: absolute;
    right: 0.2rem;
    bottom: 0.2rem;
    color: #999999;
    font-size: 0.12rem;
  }
  .btn_submit {
    width: 1.75rem;
    height: 0.4rem;
    border-radius: 0.2rem;
    background: #dc2727;
    line-height: 0.4rem;
    text-align: center;
    margin: 0.35rem auto;
    display: block;
    border: none;
    font-size: 0.18rem;
    color: #fff;
  }
  .btn_to_list {
    font-size: 0.12rem;
    color: #ea7c6c;
    text-align: center;
    border: none;
    margin: 0.4rem auto 0.2rem;
    display: block;
    text-decoration: underline;
  }
  .pop_box {
    padding: 0.15rem 0 0;
    box-sizing: border-box;
  }
  .state {
    right: 0;
    top: 0.05rem;
    border-radius: 0 0.07rem;
    font-size: 0.1rem;
    color: #dc2727;
    padding: 0.03rem 0.06rem;
    text-align: center;
    &.online {
      color: #246ede;
    }
  }
}
</style>

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"item_box menu"},[_c('p',{staticClass:"force"},[_c('SubTitle',{staticClass:"flex_item",attrs:{"title":'问题类型：'}}),_c('span',{staticClass:"feedback"},[_vm._v(_vm._s(_vm.classificationFunc(_vm.classification)))])],1)]),(_vm.classification === 1)?_c('div',{staticClass:"item_box flex flex_align_center pr",staticStyle:{"padding":"0"}},[(Object.keys(_vm.selectPro).length !== 0)?_c('DetailListCell',{attrs:{"data":_vm.selectPro,"isEdit":_vm.isEdit}}):_vm._e(),_c('div',{staticClass:"pa state",class:{ online: _vm.state == '已受理' }},[_vm._v(" "+_vm._s(_vm.state)+" ")])],1):_vm._e(),_c('div',{staticClass:"item_box"},[_c('div',{staticClass:"flex flex_align_center",on:{"click":_vm.menuIndex}},[_c('SubTitle',{attrs:{"title":'问题描述：'}}),_c('span',{staticClass:"flex_item issue"},[_vm._v(_vm._s(_vm.menuText))])],1),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"textarea",attrs:{"placeholder":"请输入您要填写的问题描述","name":"","id":"","cols":"30","rows":"10","maxlength":"100","disabled":!_vm.isEdit},domProps:{"value":(_vm.content)},on:{"input":function($event){if($event.target.composing){ return; }_vm.content=$event.target.value}}})]),(!_vm.isEdit && _vm.replyContent)?_c('div',{staticClass:"item_box"},[_vm._m(0),_c('div',{staticClass:"message"},[_vm._v(_vm._s(_vm.replyContent))])]):_vm._e(),(_vm.classification === 1)?_c('div',{staticClass:"item_box"},[_c('div',[_c('SubTitle',{staticStyle:{"margin-bottom":"0.1rem"},attrs:{"title":'批次号图片'}}),_c('UploadImg',{attrs:{"proImgList":_vm.piImgList,"isEdit":_vm.isEdit},on:{"addImg":function (data) {
          this$1.piImgList.push(data);
        },"removeImg":function (data) {
          this$1.piImgList.forEach(function (item, index) {
            if (item.url == data) {
              this$1.piImgList.splice(index, 1);
              return false;
            }
          });
        }}}),_c('SubTitle',{staticStyle:{"margin":"0.1rem 0 0.1rem"},attrs:{"title":'产品图片'}}),_c('UploadImg',{attrs:{"proImgList":_vm.proImgList,"isEdit":_vm.isEdit},on:{"addImg":function (data) {
          this$1.proImgList.push(data);
        },"removeImg":function (data) {
          this$1.proImgList.forEach(function (item, index) {
            if (item.url == data) {
              this$1.proImgList.splice(index, 1);
              return false;
            }
          });
        }}})],1)]):_vm._e(),(_vm.classification !== 1 && _vm.replenishImgList && _vm.replenishImgList.length > 0)?_c('div',{staticClass:"item_box"},[_c('div',[_c('SubTitle',{staticStyle:{"margin":"0.1rem 0 0.1rem"},attrs:{"title":'图片补充'}}),_c('UploadImg',{attrs:{"proImgList":_vm.replenishImgList,"isEdit":_vm.isEdit},on:{"addImg":function (data) {
          this$1.replenishImgList.push(data);
        },"removeImg":function (data) {
          this$1.replenishImgList.forEach(function (item, index) {
            if (item.url == data) {
              this$1.replenishImgList.splice(index, 1);
              return false;
            }
          });
        }}})],1)]):_vm._e(),_c('van-popup',{style:({ height: '50%' }),attrs:{"close-on-click-overlay":true,"round":"","position":"bottom"},model:{value:(_vm.isShowClass),callback:function ($$v) {_vm.isShowClass=$$v},expression:"isShowClass"}},_vm._l((_vm.classList),function(item,index){return _c('div',{key:index,staticClass:"flex flex_align_center list",on:{"click":function($event){return _vm.selectClass(item.text, index)}}},[_c('div',{staticClass:"flex_item role"},[_vm._v(_vm._s(index + 1)+"、"+_vm._s(item.text))]),_c('div',{staticClass:"icon_radio",class:{ ac: item.isSelect }})])}),0),_c('van-popup',{style:({ height: '30%' }),attrs:{"close-on-click-overlay":true,"round":"","position":"bottom"},model:{value:(_vm.isShowTwoClass),callback:function ($$v) {_vm.isShowTwoClass=$$v},expression:"isShowTwoClass"}},_vm._l((_vm.menuList),function(item,index){return _c('div',{key:index,staticClass:"flex flex_align_center list",on:{"click":function($event){return _vm.selectTwoClass(item.questionsMenu, index, item.menuId)}}},[_c('div',{staticClass:"flex_item role"},[_vm._v(" "+_vm._s(index + 1)+"、"+_vm._s(item.questionsMenu)+" ")]),_c('div',{staticClass:"icon_radio",class:{ ac: item.isSelect }})])}),0),_c('van-popup',{staticClass:"pop_box",style:({ height: '63%' }),attrs:{"round":"","position":"bottom"},model:{value:(_vm.isShowProDialog),callback:function ($$v) {_vm.isShowProDialog=$$v},expression:"isShowProDialog"}},[_c('SelectList',{attrs:{"proList":_vm.proList},on:{"onSelectPro":_vm.onSelectPro,"addProList":function (data) {
          this$1.proList = data;
        }}})],1),(_vm.isEdit)?_c('div',[_c('button',{staticClass:"btn_submit",on:{"click":_vm.submitFeedback}},[_vm._v("提交")]),_c('router-link',{attrs:{"to":"/feedback"}},[_c('button',{staticClass:"btn_to_list"},[_vm._v("反馈历史")])])],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"service"},[_c('span',{staticClass:"icon_index"}),_c('span',{staticClass:"email"},[_vm._v("客服回复：")])])}]

export { render, staticRenderFns }